import React, { useState, useContext, useEffect } from "react";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import "./layout.css"
import Header from "../components/header/Header";
import TableView from "../components/table-view/TableView";
import SendData from "../components/send-data/SendData";
import Excelexport from "../components/excel-export/Excelexport";
import CircularProgress from '@mui/material/CircularProgress';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import UserContext from "../context/UserContext";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};


const Layout = (props) => {

    const [asn, setAsn] = useState(null);
    const [cargando, setCargando] = useState(false);
    const [guia, setGuia] = useState(null);
    const context = useContext(UserContext);
    const [errorValidacion, setErrorValidacion] = useState(false);
    const [errorValidaAsn, setErrorValidaAsn] = useState(false);
    const [errorValidacionGuia, setErrorValidacionGuia] = useState(false);

    const token = window.location.search.split("token=")[1];

    useEffect(() => {
        if (token) {
            context.setUserToken({ token: token })
        }

    }, []);

    const back = () => {
        window.location.href = "https://componente-autopista.prod.forus-sistemas.com/resumen";
    }

    return (
        <React.Fragment>
            <CssBaseline />
            {token ?
                <>
                    <CssBaseline />
                    <AppBar style={{ backgroundColor: '#EDF0F3', maxWidth: '96%', marginRight: '2vw', color: 'black' }}>
                        <Toolbar>
                            <Typography variant="h6" component="div">
                                <Header asn={asn} setAsn={setAsn} cargando={cargando} setCargando={setCargando} guia={guia} setGuia={setGuia} errorValidaAsn={errorValidaAsn} setErrorValidaAsn={setErrorValidaAsn} errorValidacion={errorValidacion} setErrorValidacion={setErrorValidacion} errorValidacionGuia={errorValidacionGuia} setErrorValidacionGuia={setErrorValidacionGuia}></Header>

                            </Typography>
                        </Toolbar>

                    </AppBar>
                    <Toolbar id="back-to-top-anchor" />
                    <br />
                    <br />
                    <div className="home-container">
                    <HomeOutlinedIcon className="home-icon" onClick={back} style={{ cursor: 'pointer' }} /><p className="home-title"> / Cargar ASN</p>

                    </div>

                    <Container className="table-container">
                        {errorValidacionGuia ? <Alert severity="warning">No es posible realizar carga, Número de Guia debe ser numerico.</Alert> : null}
                        {errorValidacion ? <Alert severity="warning">No es posible realizar carga, Guia y/o Archivo no ingresado.</Alert> : null}
                        {errorValidaAsn ? <Alert severity="error">Existen errores en la carga de archivo, confirme que el archivo cumpla con la estructura y sus tipos de dato.</Alert> : null}

                        <Box sx={{ my: 2 }}>
                            {asn != null ? <SendData asn={asn} setAsn={setAsn} guia={guia}></SendData> : null}

                        </Box>
                        <Box sx={{ my: 2 }}>
                            {asn != null ? <TableView asn={asn} setAsn={setAsn}></TableView> : null}
                            {cargando === true ? <CircularProgress size={120} className="cargando" /> : null}
                        </Box>
                    </Container>
                    <ScrollTop {...props}>
                        <Fab color="secondary" size="small" aria-label="scroll back to top" style={{ backgroundColor: '#0c299b' }}>
                            <KeyboardArrowUpIcon />
                        </Fab>
                    </ScrollTop>
                </>
                :
                <Card sx={{ maxWidth: 500 }} className="mensaje">
                    <CardMedia
                        component="img"
                        height="250"
                        image="permiso_denegado.jpg"
                        alt="permiso_denegado"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Mensaje de seguridad
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Debe iniciar sesion con su usuario y contraseña
                        </Typography>
                    </CardContent>
                </Card>
            }


        </React.Fragment>
    )
}

export default Layout;