import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const TableView = (props) => {

    return (
        <TableContainer component={Paper}>
            <Table aria-label="custom table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Fila</TableCell>
                        <TableCell align="left">Label</TableCell>
                        <TableCell align="left">Orden</TableCell>
                        <TableCell align="left">Sku</TableCell>
                        <TableCell align="left">Sku Supplier</TableCell>
                        <TableCell align="left">Descripción</TableCell>
                        <TableCell align="left">Fecha Compra</TableCell>
                        <TableCell align="left">Fecha Cambio</TableCell>
                        <TableCell align="left">Motivo</TableCell>
                        <TableCell align="left">Validacion</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.asn.map((row, i) => (
                        <TableRow key={i} style={row.validacion == 'error' ? { backgroundColor: 'red' } : null}>
                            <TableCell align="left">{row.fila}</TableCell>
                            <TableCell align="left">{row.label}</TableCell>
                            <TableCell align="left">{row.orden}</TableCell>
                            <TableCell align="left">{row.sku}</TableCell>
                            <TableCell align="left">{row.skuSupplier}</TableCell>
                            <TableCell align="left">{row.descripcion}</TableCell>
                            <TableCell align="left">{row.fechaCompra}</TableCell>
                            <TableCell align="left">{row.fechaCambio}</TableCell>
                            <TableCell align="left">{row.motivo}</TableCell>
                            <TableCell align="left">{row.validacion}</TableCell>
                        </TableRow>
                    ))
                    }


                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableView;