import React, { useState } from 'react';
import Fab from '@mui/material/Fab';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Excelexport from "../excel-export/Excelexport";
import "./header.css";
import axios from 'axios';

const Header = (props) => {
    const [open, setOpen] = useState(false);

    const [file, setFile] = useState([]);

    const guia = props.guia;
    const setGuia = props.setGuia;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const cambiarArchivo = (event) => {
        setFile(event.target.files[0])
    }

    const enviarArchivo = (event) => {
        event.preventDefault();
        handleClose();
        props.setCargando(true);
        props.setAsn(null);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("guia", guia);


        if(isNaN(parseInt(guia))){
            props.setErrorValidacionGuia(true);
            props.setCargando(false);
        }
        else if(guia == null || file.length == 0 || isNaN(parseInt(guia))){
            props.setErrorValidacion(true);
            props.setCargando(false);
            props.setErrorValidacionGuia(false);
        }
        else{
            props.setErrorValidacion(false);
            props.setErrorValidacionGuia(false);
            axios.post('https://autopista.prod.forus-sistemas.com/microservicio-autopista-devoluciones/devolucion/validar-asn', formData)
                .then(res => {
                    props.setCargando(false);
                    props.setAsn(res.data)
                    props.setErrorValidaAsn(false);
                })
                .catch(err => {
                    console.log("Error: " + err)
                    props.setCargando(false);
                    props.setErrorValidaAsn(true);
                });
        }
    }


    return (
        <><div className="header-container">
            <div className="titulo">
                <h2>Bodega San Francisco - Devoluciones</h2>
            </div>

            <div className="cargar">
                <Fab variant="outlined" onClick={handleClickOpen} style={{ backgroundColor: '#0c299b', color: 'white' }}>
                    <CloudUploadRoundedIcon />
                </Fab>
            </div>

            <div className="cargar">
                 <Excelexport></Excelexport>
            </div>


            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Cargar archivo</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Debe subir un archivo en formato XLSX
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="guia"
                        label="Número de Guia"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={e => setGuia(e.target.value)}
                    />

                    <TextField
                        autoFocus
                        margin="dense"
                        id="file"
                        label="Archivo"
                        type="file"
                        fullWidth
                        variant="standard"
                        onChange={cambiarArchivo}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={enviarArchivo}>Subir</Button>
                </DialogActions>
            </Dialog>
        </div>
        </>
    )
}

export default Header;

