import UserContext from './context/UserContext';
import { useState } from 'react';
import './App.css';
import Layout from './layout/Layout';

function App() {

  const [userToken, setUserToken] = useState({});

  return (
    <UserContext.Provider value={{ userToken, setUserToken }}>
      <Layout></Layout>
    </UserContext.Provider>
  );
}

export default App;
