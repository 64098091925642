import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';

export const ExportExcel = () => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const head = ["Label", "Orden", "Sku", "Sku Supplier", "Descripcion", "Fecha compra", "Fecha cambio", "Motivo"];
    const body = [];

    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(body, {header: head, skipHeader: false});
        ws['!cols'] = [{ width: 10 }, { width: 10 }, { width: 10 }, { width: 20 }, { width: 20 },{ width: 15 },{ width: 15 },{ width: 15 }];

        const wb = { Sheets: { 'Carga_ASN': ws }, SheetNames: ['Carga_ASN'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, 'Template-Carga-Asn' + fileExtension);
    }

    return (
        <div>
             <Fab variant="outlined" onClick={exportToExcel} style={{ backgroundColor: '#0c299b', color: 'white' }}>
                 <DownloadIcon />
             </Fab>
        </div>
    );
};

export default ExportExcel