import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import BugReportIcon from '@mui/icons-material/BugReport';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import "./send-data.css"


const SendData = (props) => {

    const [enviar, setEnviar] = useState(null);
    const [enviando, setEnviando] = useState(null);
    const [enviandoError, setEnviandoError] = useState(null);
    const [enviandoDone, setEnviandoDone] = useState(null);

    var errors = 0;

    props.asn.filter(data => {
        if (data.validacion == 'error') errors++
    });

    const payload = { nroGuia: props.guia, itemAsnDtoList: props.asn };

    const cargarAsn = () => {
        setEnviando(true);
        axios.post('https://autopista.prod.forus-sistemas.com/microservicio-autopista-devoluciones/devolucion/cargar-asn', payload)
            .then(res => {
                console.log(res);
                setEnviar(false);
                setEnviando(false);
                setEnviandoDone(true)
            })
            .catch(err => {
                console.log(err)
                setEnviandoError(true);
            })
    }

    return (
        <>
            <Card sx={{ minWidth: "100%" }} style={{ textAlign: "left" }}>
                <CardContent>
                    <div className='info'>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Cargar ASN devoluciones
                        </Typography>
                        <br />
                        <Badge badgeContent={errors} color="error">
                            {errors > 0 ? <BugReportIcon color="action" className='icono' /> : <CheckCircleIcon color="action" className='icono' />}
                        </Badge>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {errors > 0 ? <Alert severity="error">Errores en archivo.</Alert> : <Alert severity="success">Archivo sin errores.</Alert> }
                        </Typography>
                        <Typography variant="body2">
                            {errors > 0 ? <Alert severity="error">Para subir el archivo es necesario corregir los errores.</Alert> : ''}
                        </Typography>
                    </div>
                    <div className='status'>
                        {enviando === true ? <CircularProgress size={100} /> : null}
                        {enviandoDone === true ? <CheckCircleOutlineIcon className='icon-response icon-success' /> : null}
                        {enviandoError === true ? <ErrorOutlineIcon className='icon-response icon-error' /> : null}
                    </div>
                </CardContent>
                <CardActions className='actions'>
                    <Button variant="contained" endIcon={<SendIcon />} onClick={cargarAsn}
                        className={enviar === false ? '' : errors > 0 ? true : 'btn-cargar'} disabled={enviar === false ? true : errors > 0 ? true : false}>
                        Cargar
                    </Button>
                </CardActions>
            </Card>
        </>
    )
}

export default SendData;

